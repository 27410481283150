import React from 'react';
import Layout from '../components/Layout';
import './search.scss';

export default class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLang: 'en-gb'
    };
  }

  render() {
    const {
      state: { pageLang }
    } = this;

    return (
      <Layout navAlwaysVisible={true} pageLang={pageLang}>
        <div className="searchResult-block">
          <div className="searchResult-wrap">
            <div className="searchResult-content">
              <div className="gcse-searchresults-only" />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
